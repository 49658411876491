import { Component, OnInit } from '@angular/core';

import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  standalone: false
})
export class PageNotFoundComponent implements OnInit {
  constructor(private errorService: ErrorService) {}

  ngOnInit() {
    console.info('PageNotFound route initiated.');
    this.errorService.redirectError({
      icon: 'far fa-ghost',
      title: 'Page not found.',
      description: "The page you are looking for can't be found.",
      originalUrl: '/',
      buttonText: 'Back to previous step'
    });
  }
}
