import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
  standalone: false
})
export class TermsOfUseComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<TermsOfUseComponent>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
