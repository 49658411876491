import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

import { TrackingIdsService } from '../tracking-ids.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService {
  constructor(
    private trackingIdService: TrackingIdsService,
    private route: ActivatedRoute
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const request = this.addImpersonateHeader(req);

    return next.handle(request).pipe(
      filter((event) => event instanceof HttpResponse),
      tap((res: HttpResponse<any>) => {
        if (res.headers) {
          const trackingId = res.headers.get('x-trackingid');
          const responseStatus = res.status;
          const requestPath = this.getPathFromUrl(request.url);
          this.trackingIdService.saveTrackingId(
            requestPath,
            responseStatus,
            trackingId
          );
        }
      })
    );
  }

  /**
   * Returns the path fragment after the last '/' in the url
   * @param {string} url
   */
  private getPathFromUrl(url: string): string {
    return url.slice(url.lastIndexOf('/') + 1, url.length + 1);
  }

  private addImpersonateHeader(req: HttpRequest<any>): HttpRequest<any> {
    this.route.queryParams.subscribe((params) => {
      const impersonate = params['impersonate'];

      if (impersonate && this.numbersAndLettersOnly(impersonate)) {
        req = req.clone({
          headers: req.headers.append('X-Impersonate', impersonate)
        });
      }
    });

    return req;
  }

  private numbersAndLettersOnly(input: string): boolean {
    return /^[A-Za-z0-9]*$/.test(input);
  }
}
